import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { css } from '@emotion/core'
import { constants } from '../styles/constants'

import { MdSend } from "react-icons/md"

const FreelancesPage = (props) => {
  const heroImg = props.data.hero.childImageSharp.fluid

  console.log("PROPPS", props)

  const benefits = [
    { 
      name: "Plus de mandats",
      desc: "Nous vous faisons appel pour des mandats vidéo seul.e.s ou en équipe de tournage et prod",
    },
    { 
      name: "Plus d'expérience",
      desc: "Affinez et développez vos compétences au contact d'autres professionnels vidéo en Suisse",
    },
    { 
      name: "Plus de réseau",
      desc: "Connectez-vous avez plus de clients et d'agences, ainsi que d'autres freelances en Suisse romande",
    }
  ]

  return (
    <Layout>
      <SEO title="Freelances en Suisse romande" />

      <div className="container">
        <h1 css={heroTitle}>Freelance Vidéo en Suisse romande ?</h1>
        <h2>Rejoignez notre réseau de freelances vidéo en Suisse romande <span role="img" aria-label="Drapeau Suisse en emoji">🇨🇭</span> Lausanne, Genève, Sion, Fribourg, Montreux, Neuchâtel, Bienne...</h2>
        <Img css={heroImage} fluid={heroImg} />
        <p>Vous êtes un.e freelance en Suisse romande avec des compétences de haut niveau en réalisation vidéo, montage vidéo, retouche, motion graphics, storytelling vidéo, vidéo 3D, direction artistique vidéo... Vous pouvez rejoindre notre réseau de freelances 100% suisse !</p>
      </div>

      <div className="container" css={{marginTop: "70px"}}>
        <h2><span className="stabilo" css={{color: 'black'}}>Bénéfices</span> du réseau freelance vidéo</h2>
        <div css={benefitsGrid}>
          {benefits.map( benefit => (
            <div>
              <h3>{benefit.name}</h3>
              <p>{benefit.desc}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="container" css={{marginTop: "70px"}}>
        <form name="freelance-signup" css={formStyle} data-netlify="true" method="post" netlify-honeypot="bot-field" action="/merci">
          <h3><span role="img" aria-label="Nous contacter">👋</span> Postulez pour rejoindre le réseau</h3>
          <p>Remplissez le formulaire suivant pour nous partager votre profil et compétences. Nous vous recontacterons pour toute demande d'information additionnelle ainsi que pour les prochaines étapes.</p>

          <input type="hidden" name="bot-field" aria-label="bot field" />
          <input type="hidden" name="form-name"  aria-label="form name" value="freelance-signup" />

          <div className="form-container">

            <label className="field half-width half-width-left" htmlFor="first-name">
              <span>Prénom</span>
              <input id="first-name" type="text" name="first-name" aria-label="first-name" placeholder="Emilie" required />
            </label>

            <label className="field half-width half-width-right" htmlFor="last-name">
              <span>Nom de famille</span>
              <input id="last-name" type="text" name="last-name" aria-label="last-name" placeholder="Duvalais" required />
            </label>

            <label className="field half-width half-width-left" htmlFor="email">
                <span>Votre email</span>
                <input id="email" type="email" name="email" aria-label="email" placeholder="emi@duvalaisprod.ch" required/>
            </label>

            <label className="field half-width half-width-right" htmlFor="telephone">
                <span>Votre téléphone</span>
                <input id="telephone" type="tel" name="telephone" aria-label="telephone" placeholder="0791234567" required/>
            </label>

            <label className="field half-width half-width-left" htmlFor="localisation">
                <span>Votre localisation (où êtes-vous basé.e ?)</span>
                <input id="localisation" type="text" name="localisation" aria-label="localisation" placeholder="Lausanne" required/>
            </label>

            <label className="field half-width half-width-right" htmlFor="years-experience">
                <span>Combien d'années d'expérience avez-vous ?</span>
                <input id="years-experience" type="number" name="years-experience" aria-label="years-experience" placeholder="5" min="1" max="100" required/>
            </label>

            <label className="field full-width" htmlFor="cv">
                <span>Votre CV (LinkedIn ou lien vers site)</span>
                <input id="cv" type="url" name="cv" aria-label="cv" placeholder="ch.linkedin.com/in/emilie-duvalais" required/>
            </label>

            <label className="field full-width" htmlFor="portfolio">
                <span>Votre portfiolio (lien vers votre site / youtube ...)</span>
                <input id="portfolio" type="url" name="portfolio" aria-label="portfolio" placeholder="https://duvalais-prod.ch" required/>
            </label>

            <div className="checkboxes" htmlFor="business-area">
              <span>Dans quel(s) canton(s) romands exercez-vous votre activité ?</span>

              {["Genève", "Vaud", "Valais", "Fribourg", "Neuchâtel", "Berne", "Jura"].map( (canton, index) => (
                <div key={canton}>
                  <input type="checkbox" id={`canton-${index+1}`} name={`canton-${index+1}`} value={canton} />
                  <label for={`canton-${index+1}`}>{canton}</label>
                </div>
              ))}
            </div>

            <div className="checkboxes" htmlFor="skills">
              <span>Quelles sont vos compétences ?</span>

              {["Tournage", "Réalisation", "Montage", "Etalonnage", "Animation", "3D", "Motion graphics", "VFX", "Vidéo 360", "Live Streaming", "Drone", "Photographie", "Retouche", "Social Media Video", "Web Marketing", "Video Ads", "Storytelling", "Graphisme"].map( (skill, index) => (
                <div key={skill}>
                  <input type="checkbox" id={`skill-${index+1}`} name={`skill-${index+1}`} value={skill} />
                  <label for={`skill-${index+1}`}>{skill}</label>
                </div>
              ))}
            </div>

            <label className="field full-width" htmlFor="top-skill">
                <span>OK! Mais quelle est votre spécialité (top skill) ?</span>
                <input id="top-skill" type="text" name="top-skill" aria-label="top-skill" placeholder="Tournage & montage de vidéo d'entreprise" required/>
            </label>


            <label className="field half-width half-width-left" htmlFor="hourly-rate">
            <span>Quel est votre tarif horaire ?</span>
              <input id="hourly-rate" type="number" name="hourly-rate" aria-label="hourly-rate" placeholder="CHF/h" min="10" max="1000" required/>
            </label>

            <label className="field half-width half-width-right" htmlFor="daily-rate">
            <span>Quel est votre tarif journée ?</span>
              <input id="daily-rate" type="number" name="daily-rate" aria-label="daily-rate" placeholder="CHF/h" min="10" max="3000" required/>
            </label>

            <label className="field full-width" htmlFor="wish">
                <span>Quels types de mandats vous intéressent (optionnel)</span>
                <textarea id="wish" type="text" name="wish" aria-label="wish" placeholder="J'aimerais être cadreur pour le prochain Star Wars en Suisse romande" rows="4"></textarea>
            </label>

          </div>

          <div>
              <button className="send-button" type="submit" aria-label="Envoyer">Envoyer <MdSend /></button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default FreelancesPage

const heroTitle = css`
  font-size: 100px;

  @media screen and (max-width: ${constants.breakpoint1}) {
    font-size: 55px;
  }
`

const heroImage = css`
  margin: 20px 0 30px;

  img {
    border-radius: 10px;
  }
`

const formStyle = css`
  padding: 40px;
  background-color: ${constants.white};
  color: ${constants.black};

  h3 {
    color: ${constants.black};
    margin-bottom: 20px;
  }

  p {
    color: ${constants.black};
    margin-bottom: 50px;
  }
`

const benefitsGrid = css`

  @media screen and (min-width: ${constants.breakpoint1}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
  }
`

export const FreelancesQuery = graphql`
  query {
    hero: file(relativePath: { eq: "freelance.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`